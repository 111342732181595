import React, { useState, useEffect } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Button, Col, Row, Card } from 'antd';
import { Map, Marker, Tooltip, TileLayer } from 'react-leaflet-universal';

import Layout from '../components/layout';
import SEO from '../components/seo';

import 'leaflet/dist/leaflet.css';

const Index = () => {
	const [ mapModal, setMapModal ] = useState(false);

	useEffect(() => {
		document.addEventListener('click', closeMapModal, false);
		return () => document.removeEventListener('click', closeMapModal, false);
	});

	const closeMapModal = event => {
		if (mapModal && event.target.closest('.map-modal') === null)
			setMapModal(false);
	};

	return (
		<StaticQuery
			query={graphql`
		      query {
		        bgHome1: file(relativePath: { eq: "bg-home-1.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1920) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        bgHome2: file(relativePath: { eq: "bg-home-2.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1920) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        bgHome3: file(relativePath: { eq: "bg-home-3.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1920) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        bgHome4: file(relativePath: { eq: "bg-home-4.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1920) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
		        bgHome5: file(relativePath: { eq: "bg-home-5.jpg" }) {
		          childImageSharp {
		            fluid(maxWidth: 1920) {
			          ...GatsbyImageSharpFluid_withWebp
			        }
		          }
		        }
	          }
		    `}
			render={data => (
				<Layout>
					<SEO
						title="Accueil"
						description="Créé en 1996, le garage Black Way Motorcycles est le spécialiste Harley Davidson dans la région Genevoise, de l'entretien à la vente ou de la modification jusqu'à la construction de moto sur mesure."
						lang="fr"
						url="/"
					/>

					<div className="fullwidth-img">
						<Img
							className="page-background"
							fluid={data.bgHome1.childImageSharp.fluid}
							alt="BlackWay Motorcycles - Spécialiste Harley-Davidson à Genève"
							style={{
								maxWidth: '100%',
								maxHeight: '100%',
								objectFit: 'cover'
							}}
						/>
					</div>
					<div className="box-container first">
						<div className="content-block">
							<div className="content-block-bg" />
							<h2>BLACK WAY MOTORCYCLES</h2>
							<div>
								<h3>PRÉSENTATION</h3>
								<p>Créé en 1996, le garage Black Way Motorcycles est le spécialiste Harley Davidson dans la région Genevoise, de l'entretien à la vente ou de la modification jusqu'à la construction de moto sur mesure.</p>
								<p>Début 2017 le garage change de main et Jérémy devient le nouveau patron. L'équipe s'agrandit avec la venue de Benoît en tant que chef mécanicien en juin 2017 et d'Alexander notre apprenti juillet 2019.</p>
								<p>Black Way Motorcycles, c'est un petit garage local animé par une jeune équipe de passionnés qui s'efforcent de faire le maximum pour satisfaire les souhaits de leurs clients.</p>
							</div>
							<Row type="flex" justify="space-around" style={{ marginTop: 60 }} gutter={16}>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<Link to="/garage">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>ÉQUIPE ET GARAGE</h3>
											<p>Découvrez nos locaux et la team aux multiples talents de Black Way Motorcycles</p>
											<Button type="primary" ghost>Découvrir le garage</Button>
										</Card>
									</Link>
								</Col>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} onClick={() => setMapModal(true)} hoverable>
										<h3>LOCALISATION ET HORAIRES</h3>
										<p>Si vous voulez venir nous rendre visite, c'est avec plaisir que nous vous attendons à la Route du Pré-Marais 22 à Bernex toute la semaine de 8h30 à 12h et 13h30 à 18h, et le samedi de 10h à 14h non stop.</p>
										<Button type="primary" ghost>Venez nous rendre visite !</Button>
									</Card>

									<div className={`map-modal ${mapModal ? 'active' : ''}`}>
										<div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
											{() => {
												const L = require('leaflet');

												delete L.Icon.Default.prototype._getIconUrl;

												L.Icon.Default.mergeOptions({
													iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
													iconUrl: require('leaflet/dist/images/marker-icon.png'),
													shadowUrl: require('leaflet/dist/images/marker-shadow.png')
												});

												return mapModal ? (
													<Map center={[46.179960, 6.079830]} zoom={14} style={{ width: '100%', height: '100%' }} gestureHandling={true}>
														<TileLayer
															url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
															attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
														/>
														<Marker position={[46.179960, 6.079830]}>
															<Tooltip permanent>
																<strong>BLACK WAY SA</strong><br />Route du Pré-Marais 22<br />1233 Bernex, Genève
															</Tooltip>
														</Marker>
													</Map>
												) : null;
											}}
										</div>
									</div>
								</Col>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<Link to="/contact">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>CONTACT</h3>
											<p>Pour toutes informations complèmentaires vous pouvez nous contacter par téléphone au +41.22.757.70.21, par mail à info@black-way.com ou avec le formulaire de contact suivant:</p>
											<Button type="primary" ghost>Contactez-nous !</Button>
										</Card>
									</Link>
								</Col>
							</Row>
						</div>
					</div>
					{/*<div className="fullwidth-img">
						<Img
							className="page-background"
							fluid={data.bgHome2.childImageSharp.fluid}
							alt="BlackWay Motorcycles - Spécialiste Harley-Davidson à Genève"
							style={{
								maxWidth: '100%',
								maxHeight: '100%',
								objectFit: 'cover'
							}}
						/>
					</div>
					<div className="box-container">
						<div className="content-block">
							<div className="content-block-bg" />
							<h2>NEWS</h2>

						</div>
					</div>*/}
					<div className="fullwidth-img">
						<Img
							className="page-background"
							fluid={data.bgHome3.childImageSharp.fluid}
							alt="BlackWay Motorcycles - Spécialiste Harley-Davidson à Genève"
							style={{
								maxWidth: '100%',
								maxHeight: '100%',
								objectFit: 'cover'
							}}
						/>
					</div>
					<div className="box-container">
						<div className="content-block">
							<div className="content-block-bg" />
							<h2>NOS PRODUITS</h2>

							<Row type="flex" justify="space-around" gutter={16}>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<Link to="/customs">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>CUSTOMS</h3>
											<p>Retrouver nos différents modèles Customs d'Harley Davidson</p>
											<Button type="primary" ghost>Découvrez nos réalisations !</Button>
										</Card>
									</Link>
								</Col>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<a href="https://www.harley-davidson.com/ch/fr/motorcycles/index.html" target="_blank" rel="noopener nofollow">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>VÉHICULES NEUFS</h3>
											<p>Retrouver les différents modèles neufs d'Harley Davidson.</p>
											<Button type="primary" ghost>Découvrez la gamme H-D !</Button>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8} xxl={6}>
									<Link to="/occasions">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>VÉHICULES D'OCCASIONS</h3>
											<p>Retrouver toutes nos motos d'occasion.</p>
											<Button type="primary" ghost>Découvrez nos occasions !</Button>
										</Card>
									</Link>
								</Col>
							</Row>
						</div>
					</div>
					<div className="fullwidth-img">
						<Img
							className="page-background"
							fluid={data.bgHome4.childImageSharp.fluid}
							alt="BlackWay Motorcycles - Spécialiste Harley-Davidson à Genève"
							style={{
								maxWidth: '100%',
								maxHeight: '100%',
								objectFit: 'cover'
							}}
						/>
					</div>
					<div className="box-container">
						<div className="content-block">
							<div className="content-block-bg" />
							<h2>NOS SERVICES</h2>

							<Row type="flex" justify="space-around" gutter={16}>
								<Col xs={24} md={12} xxl={10}>
									<Link to="/atelier">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>ENTRETIENS</h3>
											<p>Entretien, service, contrôles techniques, homologations et mise en place d'accessoire sur votre véhicule.</p>
											<Button type="primary" ghost>Voir tous les services</Button>
										</Card>
									</Link>
								</Col>
								<Col xs={24} md={12} xxl={10}>
									<Link to="/atelier">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>DÉPÔT VENTE</h3>
											<p>Système de dépot-vente avantageux pour votre véhicule.</p>
											<Button type="primary" ghost>Voir tous les services</Button>
										</Card>
									</Link>
								</Col>
							</Row>
						</div>
					</div>
					<div className="fullwidth-img">
						<Img
							className="page-background"
							fluid={data.bgHome5.childImageSharp.fluid}
							alt="BlackWay Motorcycles - Spécialiste Harley-Davidson à Genève"
							style={{
								maxWidth: '100%',
								maxHeight: '100%',
								objectFit: 'cover'
							}}
						/>
					</div>
					<div className="box-container last">
						<div className="content-block">
							<div className="content-block-bg" />
							<h2>PARTENAIRES</h2>

							<Row type="flex" justify="space-around" gutter={16}>
								<Col xs={24} md={12} xl={8}>
									<a href="https://jessedesign.ch/" target="_blank" rel="noreferrer noopener">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>JESSE DESIGN</h3>
											<p>Notre peintre préférer et attitrer.</p>
											<small>https://jessedesign.ch/</small>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8}>
									<a href="http://www.gaidin.net/" target="_blank" rel="noreferrer noopener">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>GAIDIN</h3>
											<p>Celui qui mets une touche «Handmade» aux motos avec son savoir-faire en pinstriping.</p>
											<small>http://www.gaidin.net/</small>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8}>
									<a href="https://www.facebook.com/twinspeedshop/" target="_blank" rel="noreferrer noopener">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>TWIN SPEED SHOP</h3>
											<p>LE spécialiste du moteur HD, de la réparation à la préparation moteur nous lui faisons entièrement confiance.</p>
											<small>https://www.facebook.com/twinspeedshop/</small>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8}>
									<a href="https://www.sdubouloz.ch/" target="_blank" rel="noreferrer noopener">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>SDUBOULOZ</h3>
											<p>Notre artisan sellier préférer et attitrer.</p>
											<small>https://www.sdubouloz.ch/</small>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8}>
									<a href="https://gva-composites.ch/" target="_blank" rel="noreferrer noopener">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>GVA COMPOSITES</h3>
											<p>Spécialiste de la fibre de carbone et celui qui nous répare nos pièces en fibre et le traitement des réservoirs d'essence.</p>
											<small>https://gva-composites.ch/</small>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8}>
									<a href="https://www.facebook.com/TD-Arts-Kustom-1207139752776483/" target="_blank" rel="noreferrer noopener">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>TD ARTS KUSTOM</h3>
											<p>Envie d'une pièce unique et fait à la main ? c'est par ici que ça se passe.</p>
											<small>https://www.facebook.com/TD-Arts-Kustom-1207139752776483/</small>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8}>
									<a href="https://www.cembra.ch/fr/leasing/moto/calculateur/" target="_blank" rel="noreferrer noopener">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>CEMBRA</h3>
											<p>Grâce à notre partenaire Cembra, nous sommes à même de vous proposer un services de financement très aventageux, rendant l'achat de votre futur véhicule beaucoup plus accessible.</p>
											<small>https://www.cembra.ch/fr/leasing/moto/calculateur/</small>
										</Card>
									</a>
								</Col>
								<Col xs={24} md={12} xl={8}>
									<a href="https://www.motorex.com/fr-ch/" target="_blank" rel="noreferrer noopener">
										<Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} hoverable>
											<h3>MOTOREX</h3>
											<p>Les huiles Motorex c’est un partenariat de choix et de longue date qui était déjà utilisé avant les années 50 pour les motos Jenny crée par l’arrière-grand-père de Jérémy.</p>
											<small>https://www.motorex.com/fr-ch/</small>
										</Card>
									</a>
								</Col>
							</Row>
						</div>
					</div>
				</Layout>
			)}
		/>
	);
}

export default Index;
